.main-container {
  position: relative;
}
.btn-add {
  border: none;
  padding: 7px 10px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  background: #796dec !important;
  color: white;
  position: fixed;
  right: 25px;
  bottom: 25px;
  padding: 10px 20px;
  z-index: 2;
}

.banner-card {
  padding: 10px 6px;
}
.banner-edit-delete-div {
  display: flex;
  justify-content: end !important;
  gap: 30px;
}
.banner-edit,
.banner-delete {
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 2px;
  color: wheat;
  border: none;
}
.banner-edit {
  background-color: rgb(64, 131, 198);
}
.banner-delete {
  background-color: rgb(225, 78, 78);
}
/* add-banner-popup */
.add-img-div {
  padding: 20px 40px;
  text-align: center;
  background-color: #f4eff5;
  border-radius: 10px;
}
.add-img-heading {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #796dec;
}
.banner-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.add-img-file {
  display: none;
}

.add-file-upload {
  cursor: pointer;
  height: 100px;
  width: 150px;
  border-radius: 6px;
  color: #796dec;
  border: 1px dashed #796dec;
}
.add-file-upload:hover {
  color: #9b93e8;
  border: 2px dashed #796dec;
}

.add-img-div button {
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #796dec !important;
  color: #fff;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.add-img-div button:hover {
  background-color: #796dec;
}
.upload-icon {
  margin-top: 20px;
  width: 50px;
  height: 50px;
}
.submit-banner-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}
/* delete-banner-popup */

.delete-img-div {
  background-color: #f4eff5;
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
}

.delete-img-heading {
  margin-bottom: 15px;
  color: #796dec;
  font-weight: 600;
}

.delete-btn-div {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.delete-btn-div button {
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  border: none;
}
.delete-img-heading {
  font-size: 20px;
  font-weight: 600px;
}
.delete-img-p {
  font-size: 14px;
  font-weight: 400;
  color: #796dec;
}
.delete-btn-div button:first-child {
  background-color: rgb(236, 100, 100);
  color: white;
}
.img-fluid {
  object-fit: cover;
  height: 150px !important;
}
.error-message {
  color: red;
  font-size: 0.875rem; /* Slightly smaller font size */
  margin-top: 4px; /* Space between the input and the error message */
}
.img-fluid {
  object-fit: cover;
  height: 150px !important;
}
@media(min-width:662px) {
  .img-fluid {
    object-fit: cover;
    height: 400px !important;
  }
  .banner-card {
    padding: 24px 6px;
  }
}

@media(min-width:992px) {
  .img-fluid {
    object-fit: cover;
    height: 190px !important;
  }
}

@media(min-width:1024px) {
  .img-fluid {
    object-fit: cover;
    height: 135px !important;
  }
}
@media(min-width:1300px) {
  .img-fluid {
    object-fit: cover;
    height: 160px !important;
  }
}