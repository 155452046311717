.sortable-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    gap: 15px;
 }
 .rec-exam-order-head {
    color: #796dec;
    font-weight: 700 !important;
 }
 .loader-spinner {
   color: #796dec;
 }
 .chapter-name-btn {
   padding: 10px; 
   border: 1px solid #ccc; 
   box-sizing: border-box;
 }
 