.app-bar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    color:#796dec ;
}

.b-name, .logout{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}