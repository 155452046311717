.faculty-refferal-head {
    font-size: 30px;
    font-weight: 600;
    color: #796dec;
    text-align: center;
    margin-bottom: 20px;
}
.form-control-refferal {
    margin: 20px auto;
    width: 100%;
    max-width: 300px;
}
.data-grid {
    height: 400px;
    width: 100%;
    margin-top: 20px;
}
.box-container {
    width: 500px;
    overflow-y: scroll;
   }
   .download-excel {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 16px;
}
.date-input-container {
    display: flex;
    margin-bottom: 10px;
    gap: 20px;
}
   @media(min-width:662px)  {
       .box-container {
           width: 1000px;
           overflow-y: scroll;
       }
   }
   @media(min-width:992px) {
       .box-container {
           width: 1600px;
           overflow-y: scroll;
       }
   }