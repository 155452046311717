.exam-card {
    padding: 10px 6px;
    background-color: white !important;
    text-align: start !important;
    height: 570px !important;
    overflow: auto;
  }
  .exam-text {
    color: #796dec !important;
  }
  .exam-edit-delete-div {
    display: flex;
    justify-content: end !important;
    gap: 10px;
  }
  
  .exam-delete {
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 2px;
    color: wheat;
    border: none;
  }
  .exam-edit {
    background-color: rgb(64, 131, 198);
  }
  .exam-delete {
    background-color: rgb(225, 78, 78);
  }
  .span-text-div {
    display: flex !important;
    gap: 6px;
  }
  .exam-span {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #796dec !important;
    padding: 2px 6px;
    text-align: start !important;
  }
  .exam-span-text {
    color: black;
    text-align: start !important;
  }
  @media(min-width:662px) {
    .exam-card {
        padding: 24px 6px;
      }
  }
  
  @media(min-width:992px) {
   
  }