.exam-progress-card-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 0 auto;
    width: 100%;
    max-width: 1300px;
    margin-bottom: 20px;
}
.exam-prgs-card {
    padding: 5px;
    width: 100%;
}
.course-name {
    color: #796dec;
    margin: 15px !important;
    font-size: 16px !important;
}
@media(min-width:662px) {
    .exam-progress-card-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    .course-name {
        color: #796dec;
        margin: 15px !important;
        font-size: 25px !important;
    }
}
@media(min-width:1024px) {
    .exam-progress-card-container {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }
}
@media(min-width:1300px) {
    .exam-progress-card-container {
        grid-template-columns: repeat(4, 1fr);
    }
}
