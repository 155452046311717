.record-card{
    padding: 24px 6px;
    text-align: start; 
    height: auto !important;
    max-height: 800px !important;
    /* overflow: auto; */
   
}
.rec-card-texts {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 15px !important;
}
.recorded {
    font-size: 16px !important;
}
.span-text-div {
    display: flex !important;
    gap: 6px;

}
.recorded-span {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #796dec !important;
    padding: 2px 6px;
}
.recorded-span-text {
    color: black;
}
.card-texts {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px !important;
}
.recorded-edit-delete{
    display: flex;
    justify-content: end;
    gap: 30px;
  }
  .recorded-edit,
  .recorded-delete {
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 2px;
    color: wheat;
    border: none;
  }
  .recorded-edit {
    background-color: rgb(64, 131, 198);
  }
  .recorded-delete {
    background-color: rgb(225, 78, 78);
  }
  .player {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }