.recorded-screen-container {
    display: grid;
    grid-template-columns: 1fr  ;
    margin-top: 50px;
    gap: 30px;
  }
  /* Styles for the pagination container */
#pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;

}
.loader-style {
  z-index: 9999;
}

/* Styles for pagination buttons */
#pagination button {
  background-color: transparent;
  border: none;
  color: #796dec ;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s;
}


/* Styles for the active page number */
#pagination span {
  padding: 9px;
  margin: 0 5px;
  background-color: #796dec ;
  color: white;
  border-radius: 5px;
  border-radius: 50%; 
  width: 40px; 
  height: 40px;
}
.pagination-container {
  display: flex;
  justify-content: center;
}
.accordion-header {
  margin-bottom: 0;
  color: #796dec !important;
}
.file-name-display {
  display: flex;
  justify-content: center;
  align-items: center;
}