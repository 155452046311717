.course-card {
  padding: 24px 6px;
  text-align: start;
/* 
height: 1000px !important; */
  /* max-height: 700px !important; */
  /* overflow: auto; */
}
.course-image {
  width: 100% !important;
  object-fit: cover !important;
  height: 150px !important;
}
.course {
  font-size: 16px !important;
}

.course-span {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #796dec !important;
  padding: 2px 6px;

}
/* .course-span-text {
  color: black;
}  */
.card-texts {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px !important;
}
.course-edit-delete {
  display: flex;
  justify-content: end;
  gap: 30px;
}
.course-edit,
.course-delete {
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 2px;
  color: wheat;
  border: none;
}
.course-edit {
  background-color: rgb(64, 131, 198);
}
.course-delete {
  background-color: rgb(225, 78, 78);
}
.move-to-subject {
  cursor: pointer;
}
.course-row {
  padding: 5px !important;
}
.course-label {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #796dec !important;
  padding: 2px 6px;
  width: 150px;
}
@media(min-width:442px) {
  .course-image {
    width: 100% !important;
    height: 250px !important;
    object-fit: cover !important;
}
}
@media(min-width:662px) {

  .course-image {
      width: 100% !important;
      height: 400px !important;
      object-fit: cover !important;
  }
  .course-card{
      padding: 24px 6px;
      /* height: 800px !important;
      max-height: 800px !important; */
     
  }
  
.course-row {
  padding: 5px !important;
}
.course-label {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #796dec !important;
  padding: 2px 6px;
  width: 150px;
}
}

@media(min-width:992px) {
  .course-image {
      width: 100% !important;
      height: 260px !important;
      object-fit: cover !important;
  }
  .course-card{
      padding: 24px 6px;
      height: 1095px !important;
      /* max-height: 800px !important; */
  }
}
@media(min-width:1024px) {
  .course-image {
    width: 100% !important;
    height: 275px !important;
    object-fit: cover !important;
  }
}
@media(min-width:1300px) {
  .course-image {
    width: 100% !important;
    height: 400px !important;
    object-fit: cover !important;
  }
}