.card {
    margin: 10px;
}

.chapter-container {
    display: grid; 
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Create a responsive grid */
    gap: 10px; 
}

.chapter-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; 
    border: 1px dotted #796dec;
    border-radius: 4px; 
    padding: 10px;
    cursor: pointer; 
    transition: background-color 0.3s;
}

.chapter-item:hover {
     box-shadow: 0 0 10px #544bce;
}
.chapter-name {
    color: #796dec;
    font-size: 20px;
}