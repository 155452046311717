.box-container {
    width: 500px;
    overflow-y: scroll;
   }
   .download-excel {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 16px;
}
.date-input-container {
    display: flex;
    margin-bottom: 10px;
    gap: 20px;
}
.filter-btn, .reset-btn {
    background-color:#796dec ;
    color: white;
    outline: none !important;
    border: none !important;
    border-radius: 5px;
    font-size: 16px;
}
.date-input {
    border-radius: 10px !important;
}
.filter {
    color:#796dec !important;
}
   @media(min-width:662px)  {
       .box-container {
           width: 1000px;
           overflow-y: scroll;
       }
   }
   @media(min-width:992px) {
       .box-container {
           width: 1600px;
           overflow-y: scroll;
       }
   }