.exam-component-container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
  gap: 30px;
}
.add-instruction {
  margin: 5px 10px 5px 10px !important;
  justify-content: center !important;
  color: #796dec;
  border: 1px solid #796dec !important;
  font-size: 12px !important;
  text-transform: none !important;
}
.remove-icon {
  color: rgb(236, 100, 100);
  padding: 5px 0px 5px 0px !important;
  cursor: pointer;
}
.instruction-heading {
  color: #796dec;
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 662px) {
  .exam-component-container {
    grid-template-columns: 1fr;
    margin-top: 50px;
    gap: 30px;
  }
}

@media (min-width: 992px) {
  .exam-component-container {
    grid-template-columns: 1fr 1fr;
  }
}
