.subject-card{
    padding: 24px 6px;
    text-align: start; 
}
.subject {
    font-size: 16px !important;
}
/* .span-text-div {
    display: flex !important;
    gap: 6px;

} */
 .subject-span {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #796dec !important;
    padding: 2px 6px;
}
.subject-span-text {
    color: black;
} 
.card-texts {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px !important;
}
.subject-edit-delete{
    display: flex;
    justify-content: end;
    gap: 30px;
  }
  .subject-edit,
  .subject-delete {
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 2px;
    color: wheat;
    border: none;
  }
  .subject-edit {
    background-color: rgb(64, 131, 198);
  }
  .subject-delete {
    background-color: rgb(225, 78, 78);
  }
  .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.form-control {
    display: block;
    width: 100% !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(111, 107, 107) !important;
    background-color: transparent !important;
    background-clip: padding-box;
    border: 1px solid grey !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: non;
}
.chapter-order-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #796dec;
  font-size: 20px;
  font-weight: 700;
}
