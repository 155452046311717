.course-screen-container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 50px;
  gap: 30px;
}

/* add popup */
.add-course-div {
  background-color: #f4eff5 !important;
  padding: 15px;
  width: 260px;
}
.add-popup-container {
  border-radius: 5px !important;
}
.textfields {
  width: 100%;
}
.course-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.banner-edit-delete-div {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.banner-edit,
.banner-delete {
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 2px;
  color: wheat;
  border: none;
}
.banner-edit {
  background-color: rgb(64, 131, 198);
}
.banner-delete {
  background-color: rgb(225, 78, 78);
}
.submit-banner-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.submit-banner-button-container button {
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #796dec !important;
  color: #fff;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.add-img-div button:hover {
  background-color: #796dec;
}
.heading {
  text-align: center;
  margin: 0px 0px 10px 0px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #796dec;
}

.add-file-upload {
  cursor: pointer;
  height: 80px;
  width: 100px;
  border-radius: 6px;
  color: #796dec;
  border: 1px dashed #796dec;
  display: flex;
  justify-content: center;
}
.add-file-upload:hover {
  color: #9b93e8;
  border: 2px dashed #796dec;
}
.add-img-file {
  display: none;
}
.upload-icon {
  margin-top: 10px;
  width: 50px;
  height: 50px;
}
.image-error {
  color: rgb(215, 74, 74);
  font-size: 0.875rem; /* Slightly smaller font size */
  margin-top: 4px; /* Space between the input and the error message */
}
.popup-box {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
  padding: 15px;
}
@media (min-width: 662px) {
  .add-course-div {
    padding: 20px;
    width: 400px;
  }
  .course-screen-container {
    grid-template-columns: 1fr;
    margin-top: 50px;
    gap: 30px;
  }
}

@media (min-width: 992px) {
  .add-course-div {
    padding: 20px;
    width: 400px;
  }
  .course-screen-container {
    grid-template-columns: 1fr 1fr;
  }
}
