.note-card {
  padding: 24px 6px;
  text-align: start;
  height: auto;
}
.note-span {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #796dec !important;
  padding: 2px 6px;
}
.note {
  font-size: 16px !important;
}
.note-edit-delete {
  display: flex;
  justify-content: end;
  gap: 30px;
}
.note-edit,
.note-delete {
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 2px;
  color: wheat;
  border: none;
}
.note-edit {
  background-color: rgb(64, 131, 198);
}
.note-delete {
  background-color: rgb(225, 78, 78);
}
@media (min-width: 662px) {
  .note-card {
    padding: 24px 6px;
  }
}
@media (min-width: 992px) {
}
