.note-screen-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 50px;
    gap: 30px;
  }
  @media (min-width: 662px) {
    .note-screen-container {
        grid-template-columns: 1fr;
        margin-top: 50px;
        gap: 30px;
      }
  }
  @media (min-width: 992px) {
    .note-screen-container {
        grid-template-columns: 1fr 1fr 1fr ;
      }
  }