.subject-screen-container {
  display: grid;
  grid-template-columns: 1fr !important  ;
  margin-top: 50px;
  gap: 30px;
}
.go-back-div {
  display: flex !important;
  align-items: start;
  justify-content: start !important;
}
.spinner-div {
  display: flex;
  justify-content: center;
}
.error-text {
  color: rgb(215, 74, 74);
  font-size: 0.875rem; /* Slightly smaller font size */
  margin-top: 4px; /* Space between the input and the error message */
}