.mcq-card {
    margin-top: 10px;
    padding: 24px 15px;
    text-align: start; 
    height: 650px !important;
}
.key-label {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #796dec !important;
}
.question-image, .option-image {
    width: 150px;
    height: 100px;
    object-fit: contain;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}
.option-text-image {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media (min-width: 992px) {
    .mcq-card {
        margin-top: 10px;
        padding: 24px 15px;
        text-align: start; 
        height: 550px !important;
    }
}