.faculty-card{
    padding: 10px 6px;
    text-align: start; 
    height: 890px !important;
    /* max-height: 800px !important; */
    overflow: auto;
}
.faculty-image {
    width: 50%;
    height: 200px;
    object-fit: contain !important;
}
.faculty {
    font-size: 16px !important;
}
.span-text-div {
    display: flex !important;
    flex-direction: column;
    gap: 6px;

}
.faculty-span {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #796dec !important;
    padding: 2px 6px;
}
.faculty-span-text {
    color: black;
}
.card-texts {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px !important;
}
.faculty-edit-delete{
    display: flex;
    justify-content: end;
    gap: 30px;
  }
  .faculty-edit,
  .faculty-delete {
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 2px;
    color: wheat;
    border: none;
  }
  .faculty-edit {
    background-color: rgb(64, 131, 198);
  }
  .faculty-delete {
    background-color: rgb(225, 78, 78);
  }
  @media(min-width:662px) {
    .span-text-div {
        display: flex !important;
        flex-direction: row;
        gap: 6px;
    }
    .faculty-image {
        width: 50%;
        height: 300px;
        object-fit: contain !important;
    }
    .faculty-card{
        padding: 24px 6px;
        /* height: 1000px !important; */
    }
  }
  
  @media(min-width:992px) {
    .faculty-image {
        width: 50%;
        height: 300px;
        object-fit: contain !important;
    }
    .faculty-card{
        padding: 24px 6px;
        /* height: 800px !important;
        max-width: 1000px; */
    }
  }